export const pageBreakAboutPageOne = {
  id: "break1",
  backgroundImage: true,
  backgroundImageFile: "shark-bay-wa.webp",
  alt: "TBC",
  blockHeight: "small",
  backgroundColor: "",
  backgroundFilter: "brightness(50%)",
  textColor: "#FFFFFF",
  quoteText: [
    `“Reconciling with our First Nations Peoples is one of the longest standing moral dilemmas facing our nation”`,
  ],
  quoteAuthor: "- Adam Davids, Managing Director",
};

export const pageBreakAboutPageTwo = {
  id: "break1",
  backgroundImage: true,
  backgroundImageFile: "shark-bay-wa.webp",
  alt: "TBC",
  blockHeight: "small",
  backgroundColor: "",
  backgroundFilter: "brightness(50%)",
  textColor: "#FFFFFF",
  quoteText: [
    `“Reconciling with our First Nations Peoples is one of the longest standing moral dilemmas facing our nation”`,
  ],
  quoteAuthor: "- Adam Davids, Chief Impact Officer",
};

export const pageBreakOurApproachPage = {
  id: "break1",
  backgroundImage: true,
  backgroundImageFile: "shark-bay-wa.webp",
  alt: "TBC",
  blockHeight: "large",
  backgroundColor: "",
  backgroundFilter: "brightness(50%)",
  textColor: "#FFFFFF",
  quoteText: [
    `“Reconciling with our First Nations Peoples is one of the longest standing moral dilemmas facing our nation”`,
  ],
  quoteAuthor: "- Adam Davids, Chief Impact Officer",
};

export const pageBreakInsightsPage = {
  id: "break1",
  backgroundImage: true,
  backgroundImageFile: "shark-bay-wa.webp",
  alt: "TBC",
  blockHeight: "small",
  backgroundColor: "",
  backgroundFilter: "brightness(50%)",
  textColor: "#FFFFFF",
  quoteText: [
    `“Reconciling with our First Nations Peoples is one of the longest standing moral dilemmas facing our nation”`,
  ],
  quoteAuthor: "- Adam Davids, Chief Impact Officer",
};

export const pageBreakHomePageOne = {
  id: "fneOneLine",
  backgroundImage: false,
  backgroundImageFile: "",
  alt: "TBC",
  blockHeight: "small",
  backgroundColor: "#F7F7F7",
  backgroundFilter: "brightness(100%)",
  textColor: "#283039",
  quoteText: [
    `We are a 100% Indigenous owned and led social enterprise committed to advancing the inclusion of Indigenous Australians in the ASX\u00A0200.`,
  ],
  quoteAuth: false,
  quoteAuthor: "",
};
