// export const navBarData = {
//   headings: [
//     {
//       title: "Home",
//       link: "/",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "About",
//       link: "/about",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "Our approach",
//       link: "/our-approach",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "Contact",
//       link: "/contact",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//   ],
// };

export const navBarData = {
  headings: [
    {
      title: "Home",
      link: "/",
      subMenu: false,
      dropdownMenu: [],
    },
    {
      title: "About us",
      link: "/about-us",
      subMenu: false,
      dropdownMenu: [],
    },
    {
      title: "Our framework",
      link: "/our-framework",
      subMenu: false,
      dropdownMenu: [],
    },
    {
      title: "Services",
      link: "/services",
      subMenu: false,
      dropdownMenu: [],
    },
  ],
};

// export const navBarData = {
//   headings: [
//     {
//       title: "Home",
//       link: "/",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "About us",
//       link: "/about-us",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "Our framework",
//       link: "/our-framework",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "Services",
//       link: "/services",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "Who's involved",
//       link: "/who's-involved",
//       subMenu: true,
//       dropdownMenu: [
//         {
//           subtitle: "Companies",
//           path: "/who's-involved/companies",
//         },
//         {
//           subtitle: "Investors",
//           path: "/who's-involved/investors",
//         },
//         {
//           subtitle: "Community",
//           path: "/who's-involved/community",
//         },
//       ],
//     },
//     {
//       title: "Insights",
//       link: "/insights",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//     {
//       title: "Contact",
//       link: "/contact",
//       subMenu: false,
//       dropdownMenu: [],
//     },
//   ],
// };

export const dropdownMenuItems = [
  {
    title: "Community",
    path: "/who's-involved/community",
  },
  {
    title: "Investors",
    path: "/who's-involved/investors",
  },
  {
    title: "Companies",
    path: "/who's-involved/companies",
  },
];
