import React, { useState, useEffect } from "react";
import {
  HeroContainer,
  HeroBackground,
  HeroBackgroundLogo,
  HeroBackgroundLogoTest,
  HeroBackgroundLogoMedium,
  HeroContent,
  HeroH1,
  HeroP,
} from "./HeroElements";

const HeroBlock = ({
  heading,
  paragraph,
  textColor,
  backgroundImage,
  backgroundImageFileSmall,
  backgroundImageFileMedium,
  backgroundImageFileLarge,
  backgroundImageFileXLarge,
  backgroundTinting,
  backgroundFilter,
  alt,
  backgroundColor,
  backgroundLogo,
  backgroundLogoActive,
  backgroundLogoBrightness,
  logoFile,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const [location, setLocation] = useState(window.visualViewport.height - 80);
  const [roMovement, setRoMovement] = useState(360 * (scrollY / location));
  const [movement, setMovement] = useState(100 * (scrollY / location));

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    const handleLocation = () => {
      setLocation(window.visualViewport.height - 80);
    };
    handleLocation();
    setRoMovement(360 * (scrollY / location));
    setMovement(100 * (scrollY / location));

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY, location]);

  // Consider using the attrs method, together with a style object for frequently changed styles.
  // Example:
  //   const Component = styled.div.attrs(props => ({
  //     style: {
  //       background: props.background,
  //     },
  //   }))`width: 100%;`

  //   <Component />

  return (
    <>
      <HeroContainer
        backgroundImage={backgroundImage}
        backgroundTinting={backgroundTinting}
        backgroundColor={backgroundColor}
        bgImageFileSmall={backgroundImageFileSmall}
        bgImageFileMedium={backgroundImageFileMedium}
        bgImageFileLarge={backgroundImageFileLarge}
        bgImageFileXLarge={backgroundImageFileXLarge}
        aria-label={alt}
      >
        <HeroBackground backgroundFilter={backgroundFilter}>
          <HeroBackgroundLogo
            bgLogoActive={backgroundLogoActive}
            bgLogo={backgroundLogo}
            bgLogoBrightness={backgroundLogoBrightness}
            roMovement={roMovement}
            movement={movement}
            src={logoFile}
            alt={alt}
          />
        </HeroBackground>
        <HeroContent>
          <HeroH1 textColor={textColor}>{heading}</HeroH1>
          {/* <HeroP textColor={textColor}>{paragraph}</HeroP> */}
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroBlock;
