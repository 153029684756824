import styled from "styled-components";
import { device } from "../../device";

// background: ${`url(${require(`../../images/${backgroundImageFile}`)})`} no-repeat center center fixed;
// -webkit-background-size: cover;
// -moz-background-size: cover;
// -o-background-size: cover;
// background-size: cover;

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: calc(100svh - 80px);
  position: relative;
  z-index: 1;
  ${({
    backgroundImage,
    backgroundColor,
    bgImageFileSmall,
    bgImageFileMedium,
    bgImageFileLarge,
    bgImageFileXLarge,
  }) => {
    switch (backgroundImage) {
      case true:
        return `
        background-image: ${`url(${require(`../../images/${bgImageFileSmall}`)})`};
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media ${device.laptop} {
          background-image: ${`url(${require(`../../images/${bgImageFileMedium}`)})`};
        }

        @media ${device.laptopL} {
          background-image: ${`url(${require(`../../images/${bgImageFileLarge}`)})`};
        }

        @media ${device.desktop} {
          background-image: ${`url(${require(`../../images/${bgImageFileXLarge}`)})`};
        }
      `;
      default:
        return `
        background: ${backgroundColor};
        `;
    }
  }}

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: liner-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }

  @media ${device.tablet} {
    height: calc(100vh - 80px);
    justify-content: flex-start;
  }
`;

export const HeroBackground = styled.div`
  display: flex;
  align-content: center;
  justify-content: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ backgroundFilter }) => {
    switch (backgroundFilter) {
      case "none":
        return ``;
      default:
        return `
        backdrop-filter: ${backgroundFilter};
        `;
    }
  }}
`;

/* TRYING TO MAKE LOGO DYNAMIC */
// export const NewHeroBackgroundLogo = styled.div`
//   ${({ bgLogo, bgLogoActive }) => {
//     switch (bgLogoActive) {
//       case true:
//         return `
//         background-image: ${`url(${require(`../../images/${bgLogo}`)})`};
//         background-position: center;
//         background-size: cover;
//         background-repeat: no-repeat;
//         width: 40%;
//         height: 90%;
//         margin: auto 0;
//         filter: brightness(40%);
//         transform: rotate(
//           ${({ roMovement }) => (roMovement ? roMovement : roMovement)}deg
//         );
//         `;
//       default:
//         return `
//           max-width: 50%;
//           max-height: 100%;
//           margin: auto 0;
//           filter: brightness(40%);
//           transform: rotate(
//             ${({ roMovement }) => (roMovement ? roMovement : roMovement)}deg
//           );
//           `;
//     }
//   }}
// `;

export const HeroBackgroundLogo = styled.img`
  ${({ bgLogoActive, roMovement, bgLogoBrightness }) => {
    switch (bgLogoActive) {
      case true:
        return `
          max-width: 50%;
          max-height: 100%;
          margin: auto 0;
          transform: rotate(${roMovement}deg);
          filter: ${bgLogoBrightness};
        `;
      default:
        return `
          display: none;
        `;
    }
  }}
`;

export const HeroBackgroundLogoTest = styled.img`
  ${({ bgLogoActive, roMovement, bgLogoBrightness }) => {
    switch (bgLogoActive) {
      case true:
        return `
          max-width: 50%;
          max-height: 100%;
          margin: auto 0;
          animation: logoSpin;
          animation-timeline: view();
          filter: ${bgLogoBrightness};
        `;
      default:
        return `
          display: none;
        `;
    }
  }}

  @keyframes logoSpin {
    from {
    }
    to {
      transform: rotate, 360;
    }
  }
`;

//   transform: translateX(${({ movement }) => (movement ? movement : movement)}%);
// rotate(
//   ${({ roMovement }) => (roMovement ? roMovement : roMovement)}deg
// )

export const HeroBackgroundLogoMedium = styled.img`
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 11.25%;
  max-width: 25%;
  max-height: 50%;
  margin: auto 0;
  transform: rotate(
    ${({ roMovement }) => (roMovement ? roMovement : roMovement)}deg
  );
`;

export const HeroContent = styled.div`
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.MobileM} {
    justify-content: flex-start;
  }

  @media ${device.tablet} {
    width: 60%;
    padding: 8px 24px;
    flex-wrap: wrap;
  }
`;

export const HeroH1 = styled.h1`
  color: ${(props) => props.textColor};
  font-size: 2.5rem;
  text-align: left;
  width: 100%;
  max-width: 700px;

  @media ${device.MobileM} {
    width: 80%;
    margin-left: 0;
    align-self: flex-start;
  }

  @media ${device.tablet} {
    width: 80%;
  }

  @media ${device.laptop} {
    font-size: 3.75rem;
  }
`;
// white-space: pre;

export const HeroP = styled.p`
  margin-top: 24px;
  color: ${(props) => props.textColor};
  font-size: 1.5rem;
  text-align: left;
  width: 100%;
  max-width: 700px;

  @media ${device.tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 80%;
  }

  @media ${device.laptop} {
    font-size: 1.875rem;
  }
`;
