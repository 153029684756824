import React from "react";
import {
  JoinUsContainer,
  BackgroundEffectContainer,
  BackgroundLogo,
  InfoWrapper,
  InfoRow,
  JoinUsRow,
  Column1,
  Column2,
  Column3,
  Heading,
  HeadingWrapper,
  Paragraph,
  ParagraphsWrapper,
  ReasonsWrapper,
  ReasonContainer,
  ReasonTitleWrapper,
  ReasonTitle,
  ReasonDescriptionWrapper,
  ReasonDescription,
} from "./JoinUsElements";
import Button from "../Button";

const JoinUsBlock = ({
  heading,
  paragraphs,
  backgroundImage,
  backgroundImageFile,
  backgroundColor,
  backgroundLogoEffect,
  backgroundLogoEffectAlignment,
  backgroundLogoBrightness,
  headingTextColor,
  textColor,
  addButton,
  btnTextColor,
  btnHoverTextColor,
  btnHoverBgColor,
  btnLink,
  btnText,
  btnAlignment,
  logoFile,
  reasons,
  reasonTitleColor,
}) => {
  return (
    <>
      <JoinUsContainer
        backgroundImage={backgroundImage}
        backgroundImageFile={backgroundImageFile}
        backgroundColor={backgroundColor}
      >
        {backgroundLogoEffect ? (
          <BackgroundEffectContainer>
            <BackgroundLogo
              src={logoFile}
              logoBrightness={backgroundLogoBrightness}
              alt=""
            />
          </BackgroundEffectContainer>
        ) : null}
        <InfoWrapper>
          <JoinUsRow>
            <Column1>
              <HeadingWrapper>
                <Heading textColor={headingTextColor}>{heading}</Heading>
              </HeadingWrapper>
            </Column1>
            <Column2>
              <ParagraphsWrapper>
                {paragraphs.map((paragraph, index) => (
                  <Paragraph textColor={textColor} key={index}>
                    {paragraph}
                  </Paragraph>
                ))}
              </ParagraphsWrapper>
              {addButton ? (
                <Button
                  btnTextColor={btnTextColor}
                  btnHoverTextColor={btnHoverTextColor}
                  btnHoverBgColor={btnHoverBgColor}
                  btnLink={btnLink}
                  btnText={btnText}
                  btnAlignment={btnAlignment}
                />
              ) : null}
            </Column2>
            <Column3>
              <ReasonsWrapper>
                {reasons.map((reason, index) => (
                  <ReasonContainer>
                    <ReasonTitleWrapper>
                      <ReasonTitle textColor={reasonTitleColor} key={index}>
                        {reason.title}
                      </ReasonTitle>
                    </ReasonTitleWrapper>
                    <ReasonDescriptionWrapper>
                      <ReasonDescription>
                        {reason.description}
                      </ReasonDescription>
                    </ReasonDescriptionWrapper>
                  </ReasonContainer>
                ))}
              </ReasonsWrapper>
            </Column3>
          </JoinUsRow>
        </InfoWrapper>
      </JoinUsContainer>
    </>
  );
};

export default JoinUsBlock;
