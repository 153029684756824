import React from "react";
import "./App.css";
import NavbarTemp from "./components/NavbarTemp";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { navBarData } from "./components/NavbarTemp/data";

function App() {
  return (
    <Router>
      <NavbarTemp {...navBarData} />
      <Routes>
        <Route path="/" exact element={<HomePage id="HomePage" />} />
        <Route path="*" element={<HomePage id="HomePage" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
