import React from "react";
import {
  ButtonContainer,
  ButtonWrapper,
  Btn,
  ButtonText,
} from "./ButtonElements";

const Button = ({
  btnTextColor,
  btnHoverTextColor,
  btnHoverBgColor,
  btnLink,
  btnText,
  btnalignment,
}) => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ButtonContainer>
        <ButtonWrapper to={btnLink} btnalignment={btnalignment}>
          <Btn
            borderColor={btnTextColor}
            btnHoverBgColor={btnHoverBgColor}
            btnHoverTextColor={btnHoverTextColor}
            onClick={goToTop}
          >
            <ButtonText btnTextColor={btnTextColor}>{btnText}</ButtonText>
          </Btn>
        </ButtonWrapper>
      </ButtonContainer>
    </>
  );
};

export default Button;
