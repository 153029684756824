import styled from "styled-components";
import { device } from "../../device";
//import { Link } from "react-router-dom";

export const FooterContainer = styled.div`
  background-color: #283039;
  height: 45vh;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media ${device.mobileL} {
    height: 40vh;
  }

  @media ${device.laptopL} {
    height: 30vh;
  }
`;
//@media ${device.mobileL} {
//  height: 20vh;
//}

// Original version with Address lines
// export const FooterWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(10, 10% [col-start]);
//   grid-template-rows: repeat(6, calc(100% / 6) [row-start]);
//   grid-template-areas:
//     "header header header header header header . . . . "
//     "header header header header header header . . socials1 socials1"
//     "address1 address1 address1 address1 address1 address1 . . socials2 socials2"
//     "address2 address2 address2 address2 address2 address2 . . . ."
//     "email email email email email email . . . ."
//     "copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright";
//   height: 100%;
//   width: 90%;
//   max-width: 1350px;
//   margin: auto;

//   @media ${device.mobileL} {
//     grid-template-areas:
//       "header header header header header header . . . . "
//       "header header header header header header . . . socials1"
//       "address1 address1 address1 address1 address1 address1 . . . socials2"
//       "address2 address2 address2 address2 address2 address2 . . . socials2"
//       "email email email email email email . . . ."
//       "copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright";
//   }
// `;

export const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 10% [col-start]);
  grid-template-rows: repeat(6, calc(100% / 6) [row-start]);
  grid-template-areas:
    "header header header header header header . . . . "
    "header header header header header header . . socials1 socials1"
    "header header header header header header . . socials2 socials2"
    "header header header header header header . . . ."
    "email email email email email email . . . ."
    "copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright";
  height: 100%;
  width: 90%;
  max-width: 1350px;
  margin: auto;

  @media ${device.mobileL} {
    grid-template-areas:
      "header header header header header . . . . . "
      "header header header header header . . . . socials1"
      "header header header header header . . . socials2 socials2"
      "header header header header header . . . . ."
      "email email email email . . . . . ."
      "copyright copyright copyright copyright copyright copyright copyright copyright copyright copyright";
    height: 95%;
    width: 80%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  grid-area: header;
  align-items: center;
`;

export const Title = styled.h1`
  color: #ffffff;
  text-align: left;
  font-size: 1.5rem;

  @media ${device.mobileL} {
    font-size: 1.6rem;
  }

  @media ${device.tablet} {
    font-size: 1.8rem;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    text-align: center;
  }
`;

export const AddressOneWrapper = styled.div`
  display: flex;
  grid-area: address1;
  align-items: flex-end;
`;

export const AddressTwoWrapper = styled.div`
  display: flex;
  grid-area: address2;
  align-items: center;
`;

export const AddressOne = styled.p`
  color: #ffffff;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 0.5rem;
`;

export const AddressTwo = styled.p`
  color: #ffffff;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: auto;

  @media ${device.mobileL} {
    margin-top: 2px;
    margin-bottom: 0.5rem;
  }
`;

export const EmailWrapper = styled.div`
  display: flex;
  grid-area: email;
  align-items: center;
`;

export const Email = styled.a`
  color: #ffffff;
  font-size: 1.125rem;
  text-align: left;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  grid-area: copyright;
`;

export const Copyright = styled.p`
  color: #777;
  font-size: 1rem;
  margin: auto;
  text-align: center;
`;

export const SocialsOneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: socials1;
`;

export const SocialsTwoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: socials2;
`;

export const SocialsWrapper = styled.a`
  display: flex;
  justify-content: center;
  margin-right: 0;
  margin-left: auto;
  align-items: center;
  color: #ffffff;
  font-size: 2rem;
  transition: 0.1s ease-out;

  &:hover {
    cursor: pointer;
    color: #d37a51;
  }
`;

export const SupplyNationWrapper = styled.img`
  display: flex;
  justify-content: center;
  margin-right: 0;
  margin-left: auto;
  align-items: center;
  font-size: 2rem;
  height: 100px;
  width: auto;
`;

// export const SupplyNationWrapper = styled.div`
//   display: flex;
//   background-image: ${(props) => props.snImage};
//   background-repeat: no-repeat;
//   background-size: cover;
//   z-index: 101;
//   width: 100%;
//   height: 100%;
// `;

export const DisclaimerWrapper = styled.div`
  display: flex;
  grid-area: 5 / 8 / 5 / 11;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    grid-area: 5 / 8 / 5 / 11;
    justify-content: end;
  }

  @media ${device.laptop} {
    grid-area: 5 / 9 / 5 / 11;
  }
`;

export const Disclaimer = styled.p`
  color: #ffffff;
  font-size: 1rem;
  text-align: right;

  &:hover {
    cursor: pointer;
    color: #d37a51;
    transition: 0.1s ease-out;
  }
`;
//margin: auto 0 auto auto;

// export const FooterLink = styled(Link)`
//   color: #fff;
//   text-decoration: none;
//   margin-bottom: 0.5rem;
//   font-size: 14px;

//   &:hover {
//     color: #01bf71;
//     transition: 0.3s ease-out;
//   }
// `;

// export const SocialMedia = styled.section`
//   max-width: 1000px;
//   width: 100%;
// `;

// export const SocialMediaWrap = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   max-width: 1100px;
//   margin: 40px auto 0 auto;

//   @media screen and (max-width: 820px) {
//     flex-direction: column;
//   }
// `;

// export const SocialLogo = styled(Link)`
//   color: #fff;
//   justify-self: start;
//   cursor: pointer;
//   text-decoration: none;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-bottom: 16px;
//   font-weight: bold;
// `;

// export const WebsiteRights = styled.small`
//   color: #fff;
//   margin-bottom: 16px;
// `;

// export const SocialIcons = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 240px;
// `;

// export const SocialIconLink = styled.a`
//   color: #fff;
//   font-size: 24px;
// `;

/* PREVIOUS VERSION

export const FooterContainer = styled.div`
  display: grid;
  grid-direction: column;
  background-color: #283039;
  height: 160px;
`;

export const FooterLinkSocialContainer = styled.div``;

export const FooterWrapper = styled.div`
  display: grid;
  grid-direction: row;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinkItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 16px;
  text-align: left;
  box-sizing: border-box;
  color: #fff;
  justify-content: space-around;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLink = styled.p`
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  display: inline-block;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: #d37a51;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const FooterSocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterSocialItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  color: #ffffff;
  justify-content: space-around;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterSocialIcon = styled.a`
  color: #ffffff;
  font-size: 1.5rem;
  align-content: center;
  display: inline-block;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: #d37a51;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const FooterCloseContainer = styled.div`
  display: flex;
`;

export const FooterCloseWrapper = styled.div`
  justify-items: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterClose = styled.p`
  color: #777;
  font-size: 1rem;
  justify-content: center;
  align-content: center;
`;

*/
