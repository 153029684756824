import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import "./fonts/aeonik-bold-webfont.woff";
import "./fonts/aeonik-bold-webfont.woff2";
import "./fonts/aeonik-medium-webfont.woff";
import "./fonts/aeonik-medium-webfont.woff2";
import "./fonts/aeonik-regular-webfont.woff";
import "./fonts/aeonik-regular-webfont.woff2";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
