import React, { useState, useEffect, useRef } from "react";
import {
  Nav,
  MainNav,
  MenuIcon,
  NavBar,
  NavItemsContainer,
  LogoBox,
  LogoImage,
  NavLi,
  NavBarLink,
  StyledBars,
  StyledTimes,
  StyledChevronDown,
  StyledChevronUp,
} from "./NavbarTempElements";
// import Dropdown from "./Dropdown";
import image from "../../images/FNEP_Primary-Logo__Orange+Navy.png";
import altimage from "../../images/FNEP_Primary-Logo__Orange+Navy.webp";
import { device } from "../../device";

const NavbarTemp = ({ headings }) => {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
    setMobileSubMenu(false);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setClick(false);
    setMobileSubMenu(false);
  };

  useEffect(() => {
    if (click) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [click]);

  const closeMobileMenu = () => {
    if (window.screen.width() > device.laptop) {
      setClick(false);
    }
  };

  const [menuClick, setMenuClick] = useState(false);
  // const [dropdown, setDropdown] = useState(false);
  const handleMenuClick = () => setMenuClick(!menuClick);

  // const onMouseEnter = () => {
  //   if (window.innerWidth < 960) {
  //     setDropdown(false);
  //   } else {
  //     setDropdown(true);
  //   }
  // };

  // const onMouseLeave = () => {
  //   if (window.innerWidth < 960) {
  //     setDropdown(false);
  //   } else {
  //     setDropdown(false);
  //   }
  // };

  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const handleMobileSubMenu = (e) => {
    setMobileSubMenu(!mobileSubMenu);
    e.preventDefault();
  };

  const handleSubClick = (e) => {
    e.preventDefault();
  };

  return (
    <NavBar>
      <LogoBox to="/" aria-label="Home">
        <LogoImage
          src={altimage}
          onClick={goToTop}
          alt="First Nations Equity Partners logo"
        />
      </LogoBox>
      <Nav>
        <MainNav>
          {/* <NavItemsContainer toggle={click}>
            <MenuIcon onClick={handleClick} expandedHeight={mobileSubMenu}>
              {click ? <StyledTimes /> : <StyledBars />}
            </MenuIcon>
            {click
              ? headings.map((heading) => (
                  <NavLi
                    toggle={click}
                    key={heading.title}
                    subMenu={heading.subMenu}
                    expandedHeight={mobileSubMenu}
                  >
                    <NavBarLink
                      to={heading.link}
                      onClick={
                        heading.subMenu === false
                          ? goToTop
                          : handleMobileSubMenu
                      }
                    >
                      {heading.title}{" "}
                      {heading.subMenu === true &&
                        (mobileSubMenu ? (
                          <StyledChevronUp />
                        ) : (
                          <StyledChevronDown />
                        ))}
                    </NavBarLink>
                    {heading.subMenu === mobileSubMenu &&
                    heading.subMenu === true ? (
                      <Dropdown onClick={goToTop} toggle={mobileSubMenu} />
                    ) : null}
                  </NavLi>
                ))
              : headings.slice(1).map((heading) => (
                  <NavLi
                    toggle={click}
                    key={heading.title}
                    onMouseEnter={heading.subMenu ? onMouseEnter : undefined}
                    onMouseLeave={heading.subMenu ? onMouseLeave : undefined}
                  >
                    <NavBarLink
                      to={heading.link}
                      onClick={
                        heading.subMenu === false ? goToTop : handleSubClick
                      }
                    >
                      {heading.title}
                    </NavBarLink>
                    {heading.subMenu === dropdown &&
                    heading.subMenu === true ? (
                      <Dropdown />
                    ) : null}
                  </NavLi>
                ))}
          </NavItemsContainer> */}
        </MainNav>
      </Nav>
    </NavBar>
  );
};

export default NavbarTemp;
