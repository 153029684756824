import React from "react";
import {
  PageBreakBlockContainer,
  PageBreakBlockWrapper,
  TextContainer,
  QuoteWrapper,
  QuoteText,
  QuoteAuthor,
} from "./PageBreakElements";

const PageBreakBlock = ({
  backgroundImage,
  backgroundImageFile,
  alt,
  blockHeight,
  backgroundColor,
  backgroundFilter,
  textColor,
  quoteText,
  quoteAuthor,
  quoteAuth,
}) => {
  return (
    <>
      <PageBreakBlockContainer
        backgroundImage={backgroundImage}
        backgroundImageFile={backgroundImageFile}
        backgroundColor={backgroundColor}
        blockHeight={blockHeight}
      >
        <PageBreakBlockWrapper backgroundFilter={backgroundFilter}>
          <TextContainer>
            <QuoteWrapper>
              {quoteText.map((paragraph, index) => (
                <QuoteText textColor={textColor} key={index}>
                  {paragraph}
                </QuoteText>
              ))}
              {quoteAuth ? (
                <QuoteAuthor textColor={textColor}>{quoteAuthor}</QuoteAuthor>
              ) : null}
            </QuoteWrapper>
          </TextContainer>
        </PageBreakBlockWrapper>
      </PageBreakBlockContainer>
    </>
  );
};

export default PageBreakBlock;
