import React from "react";
import { SEO } from "../components/SEO";
import JoinUsBlock from "../components/JoinUsBlock";
import { joinUsHomePage } from "../components/JoinUsBlock/data";
import HeroBlock from "../components/HeroBlock";
import { heroHomePage } from "../components/HeroBlock/data";
import CompactTextBlock from "../components/CompactTextBlock";
import { compactTextBlockHomePage } from "../components/CompactTextBlock/data";
import PageBreakBlock from "../components/PageBreakBlock";
import { pageBreakHomePageOne } from "../components/PageBreakBlock/data";
import lavenderLogo from "../images/FNEP_Icon_Lavender.png";
import sandLogo from "../images/FNEP_Icon_Sand.png";
import orangeLogo from "../images/FNEP_Icon_Orange.png";

const HomePage = () => {
  return (
    <>
      <SEO
        title="First Nations Equity Partners"
        description="We specialise in researching and rating ASX 200 companies to identify opportunities for more meaningful engagement with Indigenous Australians that can drive real social change."
        type="website"
        name="First Nations Equity Partners"
        robots="index, follow"
      />
      <HeroBlock {...heroHomePage} logoFile={lavenderLogo} />
      <PageBreakBlock {...pageBreakHomePageOne} />
      <CompactTextBlock {...compactTextBlockHomePage} logoFile={orangeLogo} />
      <JoinUsBlock {...joinUsHomePage} logoFile={sandLogo} />
    </>
  );
};

export default HomePage;
