import styled from "styled-components";
import { device } from "../../device";

export const JoinUsContainer = styled.div`
  height: auto;
  padding: 50px 0;
  position: relative;
  z-index: 2;
  ${({ backgroundImage, backgroundImageFile, backgroundColor }) => {
    switch (backgroundImage) {
      case true:
        return `
          background-image: ${`url(${require(`../../images/${backgroundImageFile}`)})`};
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        `;
      default:
        return `
          background: ${backgroundColor};
        `;
    }
  }}

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.laptop} {
    min-height: calc(75vh - 80px);
    padding: 3rem 0;
    display: flex;
    align-items: center;
  }
`;

export const BackgroundEffectContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: none;
  z-index: -1;

  @media ${device.tablet} {
    display: flex;
  }
`;

export const BackgroundLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  filter: ${(props) => props.logoBrightness};
`;
// position: absolute;
// top: 500px;
// transform: translateY(200%);

export const InfoWrapper = styled.div`
  display: flex;
  z-index: 3;
  height: 100%;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  justify-content: flex-start;
  animation: fade-in;
  animation-timeline: view();

  @keyframes fade-in {
    from {
      scale: 0.8;
      opacity: 0;
    }
    to {
      scale: 1;
      opacity: 1:
    }
  }

  @media ${device.tablet} {
    max-width: 1350px;
  }
`;

export const JoinUsRow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  height: auto;
  max-height: calc(100% - 3rem);
  margin: auto;
  align-items: centre;

  @media ${device.laptopL} {
    max-height: calc(80% - 3rem);
  }
`;

export const Column1 = styled.div`
  margin-bottom: 0;
  text-align: center;
  width: 100%;

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  text-align: center;
  width: 100%;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const Column3 = styled.div`
  text-align: center;
  width: 100%;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  padding-bottom: 0;

  @media ${device.tablet} {
    padding-top: 0;
    padding-bottom: 0;
    margin: auto;
  }
`;

export const ParagraphsWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;

  @media ${device.tablet} {
    padding-top: 0;
    padding-bottom: 0;
    margin: auto;
  }
`;

export const ReasonsWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  width: 90%;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  margin: auto;

  @media ${device.mobileL} {
    width: 80%;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    column-gap: 25px;
    margin: 25px auto 0px auto;
    width: 100%;
  }

  @media ${device.laptopM} {
    width: 90%;
  }
`;

export const ReasonContainer = styled.div`
  flex: 1;
  flex-direction: column;
  border-top: 5px solid #d37a51;

  @media ${device.tablet} {
    display: grid;
    grid-template-rows: 1fr 1.5fr;
    align-content: center;
    height: 100%;
    border-top: none;
    border-left: 5px solid #d37a51;
    padding-left: 0.5rem;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  align-content: center;
  color: ${(props) => props.textColor};

  @media ${device.mobileL} {
    font-size: 3rem;
    margin: auto;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${(props) => props.textColor};

  @media ${device.tablet} {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
  }
`;

export const ReasonTitleWrapper = styled.div`
  @media ${device.tablet} {
    align-self: center;
  }
`;

export const ReasonTitle = styled.h2`
  line-height: 1.1;
  text-align: center;
  color: ${(props) => props.textColor};

  @media ${device.tablet} {
    text-align: start;
    font-size: 1.5rem;
    margin: auto;
  }
`;

export const ReasonDescriptionWrapper = styled.div``;

export const ReasonDescription = styled.p`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;

  @media ${device.tablet} {
    text-align: start;
  }

  @media ${device.laptopM} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;
