import React, { useState, useEffect, useRef } from "react";
import {
  DisclaimerContainer,
  BackgroundEffectContainer,
  BackgroundLogo,
  DisclaimerWrapper,
  HeadingWrapper,
  Heading,
  TextWrapper,
  Text,
  StyledTimes,
} from "./DisclaimerModalElements";
import sandLogo from "../../images/FNEP_Icon_Sand.png";

const DisclaimerModal = ({ show }) => {
  const [unmount, setUnmount] = useState(false);
  const timerRef = useRef(null);
  const closeModal = (e) => {
    e.preventDefault();
    setUnmount(true);
    timerRef.current = setTimeout(() => show(false), 450);
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <>
      <DisclaimerContainer unmount={unmount}>
        <StyledTimes onClick={closeModal} />
        <BackgroundEffectContainer>
          <BackgroundLogo
            src={sandLogo}
            alt="First Nations Equity Partners logo"
          />
        </BackgroundEffectContainer>
        <DisclaimerWrapper>
          <HeadingWrapper>
            <Heading>Important Information</Heading>
          </HeadingWrapper>
          <TextWrapper>
            <Text>
              This website is owned and operated by{" "}
              <strong>First Nations Equity Partners Pty Ltd</strong> (ABN 23 656
              101 441) (<strong>FNE</strong>). FNE is a Corporate Authorised
              Representative (AFS Representative No. 001309708) of True Oak
              Investments Ltd (ABN 81 002558 956, AFSL 238184).
            </Text>
            <Text>
              By using this website, you (<strong>Recipient</strong>)
              acknowledge and agree that the provision or content of this
              website and any accompanying material (together, the{" "}
              <strong>Information</strong>) is intended to constitute the
              provision of general information only and does not intend to
              influence any particular decision in relation to a particular
              financial product or class of financial products.
            </Text>
            <Text>
              The Information is provided for personal use of the Recipient and
              information purposes only. The Information does not take account
              of the investment objectives, financial situation and particular
              needs of any particular person and is not financial product advice
              to any class of persons.
            </Text>
            <Text>
              The Information does not constitute a recommendation to buy or
              sell any particular security or fund interest. Nothing contained
              in the Information shall be considered a solicitation for the
              offering of any investment product or service to any person in any
              jurisdiction.
            </Text>
            <Text>
              No representation, warranty or undertaking is given or made in
              relation to the accuracy or completeness of the Information, which
              is drawn from public information not verified by FNE. FNE does not
              represent it as accurate, complete, or independently verified, and
              it should not be relied on as such. As the Information is drawn
              from previously published public information of an ASX entity and
              may be subject to change without notice.{" "}
              <strong>
                Past performance in relation to any metrics is not a reliable
                indicator of future performance.
              </strong>
            </Text>
            <Text>
              While the Information has been prepared in good faith, neither FNE
              nor any of its agents, representatives, advisers, affiliates,
              directors, officers or employees accept any responsibility for or
              make any representation or warranty (either express or implied) as
              to the truth, accuracy, reliability or completeness of the
              Information. Neither FNE nor any of its agents, representatives,
              advisers, affiliates, directors, officers and employees undertake
              any obligation to provide the Recipient with additional
              information or to update the information contained therein or to
              correct any inaccuracies which may become apparent. Except for any
              liability which cannot be excluded FNE, its directors, officers,
              employees and agents disclaim all liability for any error or
              inaccuracy in, misstatement or omission from, the Information, or
              any loss or damage suffered by the Recipient or any other person
              as a consequence of relying upon the Information.
            </Text>
            <Text>
              Nothing contained in the Information should be construed as the
              provision of tax, accounting, or legal advice.
            </Text>
            <Text>
              Any unauthorised reproduction of this information is prohibited.
            </Text>
          </TextWrapper>
        </DisclaimerWrapper>
      </DisclaimerContainer>
    </>
  );
};

export default DisclaimerModal;
