import React, { useState, useEffect } from "react";
import {
  FooterContainer,
  FooterWrapper,
  TitleWrapper,
  Title,
  AddressOneWrapper,
  AddressTwoWrapper,
  AddressOne,
  AddressTwo,
  EmailWrapper,
  Email,
  CopyrightWrapper,
  Copyright,
  SocialsOneContainer,
  SocialsTwoContainer,
  SocialsWrapper,
  SupplyNationWrapper,
  DisclaimerWrapper,
  Disclaimer,
} from "./FooterElements";
import DisclaimerModal from "../DisclaimerModal";
// import { AiOutlineLinkedin } from "react-icons/ai";
// import { TfiYoutube } from "react-icons/tfi";
import supplyNationCert from "../../images/SN_Certified_ART.png";

const Footer = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const onValueChange = (input) => {
    setClick(input);
  };

  useEffect(() => {
    if (click) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [click]);

  // function onEmailClick(e) {
  //   window.location.href(`mailto:info@fnequity.com.au`);
  //   e.preventDefault();
  // }

  return (
    <>
      <FooterContainer>
        <FooterWrapper>
          <TitleWrapper>
            <Title>First Nations Equity Partners</Title>
          </TitleWrapper>
          <AddressOneWrapper>
            <AddressOne></AddressOne>
          </AddressOneWrapper>
          <AddressTwoWrapper>
            <AddressTwo></AddressTwo>
          </AddressTwoWrapper>
          <EmailWrapper>
            <Email
              href="mailto:info@fnequity.com.au"
              onClick={(e) => {
                window.location.href(`mailto:info@fnequity.com.au`);
                e.preventDefault();
              }}
            >
              info@fnequity.com.au
            </Email>
          </EmailWrapper>
          <CopyrightWrapper>
            <Copyright>
              © {new Date().getFullYear()} First Nations Equity Partners
            </Copyright>
          </CopyrightWrapper>
          <SocialsTwoContainer>
            <SupplyNationWrapper
              src={supplyNationCert}
              alt="Supply Nation Certified"
            ></SupplyNationWrapper>
            {/* <SocialsWrapper
              href="https://www.linkedin.com/company/first-nations-equity-partners/about/"
              target="_blank"
              rel="noopener"
              aria-label="FNEP LinkedIn"
            >
              <AiOutlineLinkedin />
            </SocialsWrapper> */}
          </SocialsTwoContainer>
          {/* <SocialsTwoContainer>
            <SocialsWrapper
              href="/"
              target="_blank"
              rel="noopener"
              aria-label="FNEP YouTube"
            >
              <TfiYoutube />
            </SocialsWrapper>
          </SocialsTwoContainer> */}
          <DisclaimerWrapper>
            <Disclaimer onClick={() => handleClick()}>
              Important Information
            </Disclaimer>
          </DisclaimerWrapper>
        </FooterWrapper>
      </FooterContainer>
      {click ? <DisclaimerModal show={onValueChange} /> : null}
    </>
  );
};

export default Footer;
