import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaChevronRight, FaRegArrowAltCircleRight } from "react-icons/fa";
import { device } from "../../device";

export const ButtonContainer = styled.div`
  display: flex;
  width: 80%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
`;

export const ButtonWrapper = styled(Link)`
  ${({ btnalignment }) => {
    switch (btnalignment) {
      case "center":
        return `
          justify-content: ${btnalignment};
          margin: auto;
      `;
      default:
        return `
          justify-content: ${btnalignment};
        `;
    }
  }}
`;

export const ButtonText = styled.span`
  font-size: 1.1rem;
  color: ${(props) => props.btnTextColor};
`;

export const Btn = styled.button`
  background-color: transparent;
  border: ${(props) => `2px solid ${props.borderColor}`};
  border-radius: 10px;
  text-transform: uppercase;
  transition: ease background-color 0.3s;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;

  &:hover {
    background-color: ${(props) => props.btnHoverBgColor};
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &:hover ${ButtonText} {
    color: ${(props) => props.btnHoverTextColor};
  }

  @media ${device.tablet} {
    min-width: 355px;
  }
`;
// transform: translateY(-0.1rem);
// transform: scale(1.02);

export const StyledChevRight = styled(FaChevronRight)`
  color: #ffffff;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }
`;
