export const heroHomePage = {
  id: "heroHomePage",
  heading: "Advancing First Nations\nInclusion in\nCorporate Australia",
  paragraph: "",
  textColor: "#FFFFFF",
  backgroundImage: false,
  backgroundImageFileSmall: "Dark_Blue_Graphic_BG.png",
  backgroundImageFileMedium: "Dark_Blue_Graphic_BG.png",
  backgroundImageFileLarge: "Dark_Blue_Graphic_BG.png",
  backgroundImageFileXLarge: "Dark_Blue_Graphic_BG.png",
  backgroundTinting: false,
  backgroundFilter: "none",
  alt: "First Nations Equity Partners Logo",
  backgroundColor: "#283039",
  backgroundLogo: "FNEP_Icon_Lavender.png",
  backgroundLogoActive: true,
  backgroundLogoBrightness: "brightness(70%)",
};

// export const heroServicesPage = {
//   id: "heroServicesPage",
//   heading: `[Services Page]`,
//   paragraph: "[TBC subheading text]",
//   textColor: "#FFFFFF",
//   backgroundImage: false,
//   backgroundImageFileSmall: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileMedium: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileLarge: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileXLarge: "Dark_Blue_Graphic_BG.png",
//   backgroundTinting: false,
//   backgroundFilter: "none",
//   alt: "Image of Sydney skyline",
//   backgroundColor: "#283039",
//   backgroundLogo: "../../images/FNEP_Icon_Lavender.png",
//   backgroundLogoActive: true,
//   backgroundLogoBrightness: "",
// };
// backgroundLogo: `url(${require(`../../images/FNEP_Icon_Lavender.png`)}`,
// backgroundLogo: "FNEP_Icon_Lavender.png",
// backgroundLogo: "../../images/FNEP_Icon_Lavender.png",

// export const heroInsightsPage = {
//   id: "insights-hero",
//   heading: "Insights",
//   paragraph: "TBC",
//   textColor: "#FFFFFF",
//   backgroundImage: false,
//   backgroundImageFileSmall: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileMedium: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileLarge: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileXLarge: "Dark_Blue_Graphic_BG.png",
//   backgroundTinting: false,
//   backgroundFilter: "none",
//   alt: "Image of Sydney skyline",
//   backgroundColor: "#283039",
//   backgroundLogo: "FNEP_Icon_Lavender.png",
//   backgroundLogoActive: true,
//   backgroundLogoBrightness: "",
// };

// export const heroOurApproachPage = {
//   id: "heroOurApproachPage",
//   heading: "A stewardship approach",
//   paragraph:
//     "Australia’s biggest companies and investors working together for meaningful change.",
//   textColor: "#FFFFFF",
//   backgroundImage: false,
//   backgroundImageFileSmall: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileMedium: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileLarge: "Dark_Blue_Graphic_BG.png",
//   backgroundImageFileXLarge: "Dark_Blue_Graphic_BG.png",
//   backgroundTinting: false,
//   backgroundFilter: "none",
//   alt: "Orange mural that says 'ship it' on a wall at HubSpot's Singapore office",
//   backgroundColor: "#283039",
//   backgroundLogo: "FNEP_Icon_Lavender.png",
//   backgroundLogoActive: true,
//   backgroundLogoBrightness: "brightness(70%)",
// };

// export const heroHomePageOld = {
//   id: "heroHomePage",
//   heading: "First Nations\nAustralian Equity Fund",
//   paragraph:
//     "An actively managed investment fund that delivers positive outcomes for Indigenous Australians and competitive financial returns for investors.",
//   textColor: "#FFFFFF",
//   backgroundImage: true,
//   backgroundImageFileSmall: "mari-nawi-sydney-harbour_mobileL.webp",
//   backgroundImageFileMedium: "mari-nawi-sydney-harbour_laptop.webp",
//   backgroundImageFileLarge: "mari-nawi-sydney-harbour_laptopL.webp",
//   backgroundImageFileXLarge: "mari-nawi-sydney-harbour_laptopL.webp",
//   backgroundTinting: false,
//   backgroundFilter: "brightness(70%)",
//   alt: "Tribal Warrior and Sydney Harbour Bridge",
//   backgroundColor: "#283039",
//   backgroundLogo: "FNEP_Icon_Lavender.png",
//   backgroundLogoActive: false,
//   backgroundLogoBrightness: "",
// };

// export const heroCommunityPage = {
//   id: "heroCommunityPage",
//   heading: "Community Page",
//   paragraph:
//     "An actively managed investment fund that delivers positive outcomes for Indigenous Australians and competitive financial returns for investors.",
//   textColor: "#FFFFFF",
//   backgroundImage: true,
//   backgroundImageFileSmall: "tsi-fishing.jpeg",
//   backgroundImageFileMedium: "tsi-fishing.jpeg",
//   backgroundImageFileLarge: "tsi-fishing.jpeg",
//   backgroundImageFileXLarge: "tsi-fishing.jpeg",
//   backgroundTinting: false,
//   backgroundFilter: "brightness(60%)",
//   alt: "Image of Sydney skyline",
//   backgroundColor: "#283039",
//   backgroundLogo: "FNEP_Icon_Lavender.png",
//   backgroundLogoActive: false,
//   backgroundLogoBrightness: "",
// };

// export const heroInvestorsPage = {
//   id: "heroInvestorsPage",
//   heading: "Investors Page",
//   paragraph:
//     "An actively managed investment fund that delivers positive outcomes for Indigenous Australians and competitive financial returns for investors.",
//   textColor: "#FFFFFF",
//   backgroundImage: true,
//   backgroundImageFileSmall: "tsi-fishing.jpeg",
//   backgroundImageFileMedium: "tsi-fishing.jpeg",
//   backgroundImageFileLarge: "tsi-fishing.jpeg",
//   backgroundImageFileXLarge: "tsi-fishing.jpeg",
//   backgroundTinting: false,
//   backgroundFilter: "brightness(60%)",
//   alt: "Image of Sydney skyline",
//   backgroundColor: "#283039",
//   backgroundLogo: "FNEP_Icon_Lavender.png",
//   backgroundLogoActive: false,
//   backgroundLogoBrightness: "",
// };

// export const heroCompaniesPage = {
//   id: "heroCompaniesPage",
//   heading: "Companies Page",
//   paragraph:
//     "An actively managed investment fund that delivers positive outcomes for Indigenous Australians and competitive financial returns for investors.",
//   textColor: "#FFFFFF",
//   backgroundImage: true,
//   backgroundImageFileSmall: "tsi-fishing.jpeg",
//   backgroundImageFileMedium: "tsi-fishing.jpeg",
//   backgroundImageFileLarge: "tsi-fishing.jpeg",
//   backgroundImageFileXLarge: "tsi-fishing.jpeg",
//   backgroundTinting: false,
//   backgroundFilter: "brightness(60%)",
//   alt: "Image of Sydney skyline",
//   backgroundColor: "#283039",
//   backgroundLogo: "FNEP_Icon_Lavender.png",
//   backgroundLogoActive: false,
//   backgroundLogoBrightness: "",
// };
