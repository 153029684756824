import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { device } from "../../device";

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  z-index: 100;
  height: 80px;
  top: 0px;
  margin: 0px;
  padding: 0px;
  background: #ffffff;
  border-bottom: 1px solid rgba(238, 238, 243);
`;

export const NavBarWrapper = styled.div`
  height: 80px;
  width: auto;
  max-width: 2300px;
`;

export const LogoBox = styled(Link)`
  display: absolute;
  left: 0;
  position: sticky;
  top: 0;
  width: 55%;
  height: 100%;

  @media ${device.tablet} {
    width: 40%;
  }

  @media ${device.laptop} {
    width: 20%;
  }

  @media ${device.desktop} {
    left: 100px;
    width: calc(20% - 100px);
  }
`;
// max-width: 300px;

export const LogoImage = styled.img`
  max-width: 300px;
  height: 80px;

  &:hover {
    cursor: pointer;
  }
`;

export const Nav = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 90;
  margin-top: -80px;
  width: 80%;
  left: 0;
  transition: all 0.5s ease;

  @media ${device.laptop} {
    flex-direction: row;
    background: transparent;
  }

  @media ${device.desktop} {
    left: 100px;
    width: calc(80% - 100px);
  }
`;

export const MainNav = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media ${device.laptop} {
    justify-content: flex-end;
  }
`;

export const NavItemsContainer = styled.div`
  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  align-content: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  position: absolute;
  right: 0;
  ${({ toggle }) => (toggle ? "background: #ffffff" : "")};
  ${({ toggle }) => (toggle ? "height: 100dvh" : "")};
  ${({ toggle }) => (toggle ? "width: 100svw" : "")};
  animation-name: ${({ toggle }) => (toggle ? "slideIn" : "slideOut")};
  animation-duration: ${({ toggle }) => (toggle ? "0.5s" : "0s")};
  animation-timing-function: ease;

  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    max-width: 500px;
    padding-right: 2rem;
    background: transparent;
  }
`;
// LAPTOP WIDTH = 50% when Who's Involved & Insights NOT included in Navbar headings list. i.e. only 3 Nav items (excluding Home)
// LAPTOP MAX-WIDTH = 500px when Who's Involved & Insights NOT included in Navbar headings list. i.e. only 3 Nav items (excluding Home)
// LAPTOP WIDTH = 70% when Who's Involved & Insights included in Navbar headings list. i.e. 5 Nav items (excluding Home)
// LAPTOP MAX-WIDTH = 800px when Who's Involved & Insights included in Navbar headings list. i.e. 5 Nav items (excluding Home)

export const MenuIcon = styled.div`
  display: flex;
  height: 80px;
  justify-content: right;
  align-items: center;
  padding-right: 1rem;
  ${({ expandedHeight }) => (expandedHeight ? "margin-top: 1.5rem;" : "")};

  @media ${device.laptop} {
    display: none;
    margin-bottom: 100px;
  }
`;

export const StyledBars = styled(FaBars)`
  color: #283039;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledTimes = styled(FaTimes)`
  color: #283039;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledChevronDown = styled(FaChevronDown)`
  @keyframes rotateDown {
    0% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  color: #283039;
  font-size: 1.2rem;
  animation-name: rotateDown;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledChevronUp = styled(FaChevronUp)`
  @keyframes rotateUp {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  color: #283039;
  font-size: 1.2rem;
  animation-name: rotateUp;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export const NavLi = styled.div`
  align-items: center;
  height: ${({ expandedHeight }) => (expandedHeight ? "200px" : "80px")};
  justify-content: center;
  padding-top: 1.5rem;
  display: ${({ toggle }) => (toggle ? "flex" : "none")};
  flex-direction: ${({ subMenu, toggle }) =>
    (toggle = subMenu ? "column" : "row")};
  transition: height 0.4s ease-out;

  &:last-child {
    margin-bottom: 1.5rem;
  }

  @media ${device.laptop} {
    display: flex;
    height: 80px;
    padding-top: 0;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const NavBarLink = styled(NavLink)`
  font-size: 1.5rem;
  color: #283039;
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  margin: auto;
  align-content: center;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -5px;
    left: 0;
    background: #d37a51;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &.active:after {
    transform: scaleX(1);
  }

  @media ${device.laptop} {
    display: flex;
    font-size: 1.3rem;
    color: #283039;
    height: 80px;
    margin: 0;
    align-items: center;

    &:after {
      bottom: 20px;
    }
  }
`;
