export const joinUsHomePage = {
  id: "joinUsHomePage",
  heading: "Join us.",
  paragraphs: [
    `Become a part of a trailblazing group of investors and asset managers that are committed to First\u00A0Nations inclusion in corporate Australia. Contact us for more information about how you can get involved.`,
    `info@fnequity.com.au`,
  ],
  backgroundImage: false,
  backgroundImageFile: "",
  backgroundColor: "#F7F7F7",
  backgroundLogoEffect: true,
  backgroundLogoEffectAlignment: "right",
  backgroundLogoBrightness: "brightness(95%) opacity(20%)",
  headingTextColor: "#D37A51",
  textColor: "#283039",
  addButton: false,
  btnTextColor: "#FFFFFF",
  btnHoverTextColor: "#FFFFFF",
  btnHoverBgColor: "#D37A51",
  btnLink: "/",
  btnText: "Tell me more",
  btnAlignment: "left",
  reasons: [
    {
      title: "Improve First Nations equity",
      description:
        "Influence corporate inclusion and promote large-scale change",
    },
    {
      title: "Advocate and engage",
      description:
        "Utilise exclusive research and advice to champion social progress",
    },
    {
      title: "Invest in change",
      description: "Consciously prioritise social equity and sustainability",
    },
  ],
  reasonTitleColor: "#002060",
};
