export const compactTextBlockHomePage = {
  id: "homeTheOpportunity",
  backgroundImage: false,
  backgroundImageFile: "",
  backgroundFilter: "none",
  backgroundColor: "#FFFFFF",
  backgroundLogoEffect: true,
  backgroundLogoBrightness: "brightness(100%)  opacity(15%)",
  textColor: "#283039",
  heading: "The Opportunity.",
  headingColor: "#D37A51",
  paragraphs: [
    "With a collective value of more than $2\u00A0trillion, the ASX\u00A0200 is Australia’s largest and most influential group of companies and represents a significant untapped opportunity to create real world positive outcomes with Indigenous Australians.",
    "This is why we specialise in researching and rating ASX\u00A0200 companies to identify opportunities for more meaningful engagement with Indigenous Australians that can drive real social change.",
  ],
  addButton: false,
  btnTextColor: "#283039",
  btnHoverTextColor: "#FFFFFF",
  btnHoverBgColor: "#D37A51",
  btnLink: "/our-approach",
  btnText: "Placeholder text",
  btnAlignment: "center",
};
