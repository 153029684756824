import React from "react";
import {
  StandardTextContainer,
  BackgroundEffectContainer,
  BackgroundLogo,
  TextContainer,
  HeadingWrapper,
  Heading,
  TextWrapper,
  Paragraph,
} from "./CompactTextBlockElements";
import Button from "../Button";

const CompactTextBlock = ({
  textColor,
  backgroundImage,
  backgroundImageFile,
  backgroundColor,
  backgroundLogoEffect,
  backgroundLogoBrightness,
  heading,
  headingColor,
  paragraphs,
  addButton,
  btnTextColor,
  btnHoverTextColor,
  btnHoverBgColor,
  btnLink,
  btnText,
  btnAlignment,
  logoFile,
}) => {
  return (
    <>
      <StandardTextContainer
        backgroundImage={backgroundImage}
        backgroundImageFile={backgroundImageFile}
        backgroundColor={backgroundColor}
      >
        {backgroundLogoEffect ? (
          <BackgroundEffectContainer>
            <BackgroundLogo
              src={logoFile}
              logoBrightness={backgroundLogoBrightness}
              alt=""
            />
          </BackgroundEffectContainer>
        ) : null}
        <TextContainer>
          <HeadingWrapper>
            <Heading textColor={headingColor}>{heading}</Heading>
          </HeadingWrapper>
          <TextWrapper>
            {paragraphs.map((paragraph, index) => (
              <Paragraph textColor={textColor} key={index}>
                {paragraph}
              </Paragraph>
            ))}
          </TextWrapper>
          {addButton ? (
            <Button
              btnTextColor={btnTextColor}
              btnHoverTextColor={btnHoverTextColor}
              btnHoverBgColor={btnHoverBgColor}
              btnLink={btnLink}
              btnText={btnText}
              btnalignment={btnAlignment}
            />
          ) : null}
        </TextContainer>
      </StandardTextContainer>
    </>
  );
};

export default CompactTextBlock;
